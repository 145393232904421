import { memo } from "react";

import { Modal, Spinner } from "react-bootstrap";

/**
 * Loader component.
 * Displayed as a spinner on a backdrop over a window.
 */
export const Loader = memo(({ show }) => (
  <Modal
    show={show}
    backdrop="static"
    keyboard={false}
    dialogAs={({ children }) => (
      <div
        className="modal-dialog modal-dialog-centered justify-content-center"
        children={children}
      />
    )}
  >
    <Spinner animation="border" variant="light" role="status" />
  </Modal>
));
